import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'

// Components
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'

// ================================================================================================

const PrivacyApp = ({ intl }) => {
  return (
    <Layout>
      <SEO title={`App ${intl.formatMessage({ id: 'privacy.title' })}`} />

      <Container>
        <BackHomeLink to="/">
          <FormattedMessage id="legal.backHome" />
        </BackHomeLink>
        <Content>
          <Heading level="1">
            <FormattedMessage id={`App ${intl.formatMessage({ id: 'privacy.title' })}`} />
          </Heading>
          <Heading level="2">Owner and Data Controller</Heading>
          <p>
            Pawel Sas, <br />
            Nagelsweg 22d, <br />
            20097 Hamburg
          </p>
          <p>
            Owner contact email: <a href="mailto:info@dartsscorer.app">info@dartsscorer.app</a>
          </p>
          <Heading level="2">Types of Data collected</Heading>
          <p>
            Among the types of Personal Data that this Application collects, by itself or through
            third parties, there are: Cookies; Usage Data; unique device identifiers for advertising
            (Google Advertiser ID or IDFA, for example).
          </p>
          <p>
            Complete details on each type of Personal Data collected are provided in the dedicated
            sections of this privacy policy or by specific explanation texts displayed prior to the
            Data collection. Personal Data may be freely provided by the User, or, in case of Usage
            Data, collected automatically when using this Application.
          </p>
          <p>
            Unless specified otherwise, all Data requested by this Application is mandatory and
            failure to provide this Data may make it impossible for this Application to provide its
            services. In cases where this Application specifically states that some Data is not
            mandatory, Users are free not to communicate this Data without consequences to the
            availability or the functioning of the Service.
          </p>
          <p>
            Users who are uncertain about which Personal Data is mandatory are welcome to contact
            the Owner. Any use of Cookies – or of other tracking tools – by this Application or by
            the owners of third-party services used by this Application serves the purpose of
            providing the Service required by the User, in addition to any other purposes described
            in the present document and in the Cookie Policy, if available.
          </p>
          <p>
            Users are responsible for any third-party Personal Data obtained, published or shared
            through this Application and confirm that they have the third party's consent to provide
            the Data to the Owner.
          </p>

          <Heading level="2">Mode and place of processing the Data</Heading>
          <Heading level="3">Methods of processing</Heading>
          <p>
            The Owner takes appropriate security measures to prevent unauthorized access,
            disclosure, modification, or unauthorized destruction of the Data. The Data processing
            is carried out using computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In addition to the
            Owner, in some cases, the Data may be accessible to certain types of persons in charge,
            involved with the operation of this Application (administration, sales, marketing,
            legal, system administration) or external parties (such as third-party technical service
            providers, mail carriers, hosting providers, IT companies, communications agencies)
            appointed, if necessary, as Data Processors by the Owner. The updated list of these
            parties may be requested from the Owner at any time.
          </p>

          <Heading level="3">Legal basis of processing</Heading>
          <p>
            The Owner may process Personal Data relating to Users if one of the following applies:
          </p>
          <ul>
            <li>
              Users have given their consent for one or more specific purposes. Note: Under some
              legislations the Owner may be allowed to process Personal Data until the User objects
              to such processing (“opt-out”), without having to rely on consent or any other of the
              following legal bases. This, however, does not apply, whenever the processing of
              Personal Data is subject to European data protection law;
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement with the User
              and/or for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to which the Owner is
              subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public interest or in the
              exercise of official authority vested in the Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate interests pursued by the
              Owner or by a third party.
            </li>
            <p>
              In any case, the Owner will gladly help to clarify the specific legal basis that
              applies to the processing, and in particular whether the provision of Personal Data is
              a statutory or contractual requirement, or a requirement necessary to enter into a
              contract.
            </p>
          </ul>

          <Heading level="3">Place</Heading>
          <p>
            The Data is processed at the Owner's operating offices and in any other places where the
            parties involved in the processing are located.
          </p>
          <p>
            Depending on the User's location, data transfers may involve transferring the User's
            Data to a country other than their own. To find out more about the place of processing
            of such transferred Data, Users can check the section containing details about the
            processing of Personal Data.
          </p>
          <p>
            Users are also entitled to learn about the legal basis of Data transfers to a country
            outside the European Union or to any international organization governed by public
            international law or set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </p>
          <p>
            If any such transfer takes place, Users can find out more by checking the relevant
            sections of this document or inquire with the Owner using the information provided in
            the contact section.
          </p>
          <Heading level="3">Retention time</Heading>
          <p>
            Personal Data shall be processed and stored for as long as required by the purpose they
            have been collected for. Therefore:
          </p>
          <ul>
            <li>
              Personal Data collected for purposes related to the performance of a contract between
              the Owner and the User shall be retained until such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate interests shall be
              retained as long as needed to fulfill such purposes. Users may find specific
              information regarding the legitimate interests pursued by the Owner within the
              relevant sections of this document or by contacting the Owner.
            </li>
          </ul>
          <p>
            The Owner may be allowed to retain Personal Data for a longer period whenever the User
            has given consent to such processing, as long as such consent is not withdrawn.
            Furthermore, the Owner may be obliged to retain Personal Data for a longer period
            whenever required to do so for the performance of a legal obligation or upon order of an
            authority.
          </p>
          <p>
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right
            to access, the right to erasure, the right to rectification and the right to data
            portability cannot be enforced after expiration of the retention period.
          </p>

          <Heading level="2">The purposes of processing</Heading>
          <p>
            The Data concerning the User is collected to allow the Owner to provide its Service,
            comply with its legal obligations, respond to enforcement requests, protect its rights
            and interests (or those of its Users or third parties), detect any malicious or
            fraudulent activity, as well as the following: Advertising, Infrastructure monitoring,
            User database management and Registration and authentication.
          </p>
          <p>
            For specific information about the Personal Data used for each purpose, the User may
            refer to the section “Detailed information on the processing of Personal Data”.
          </p>

          <Heading level="2">Detailed information on the processing of Personal Data</Heading>
          <p>
            Personal Data is collected for the following purposes and using the following services:
          </p>

          <Heading level="3">Registration and authentication</Heading>
          <p>
            By registering or authenticating, Users allow this Application to identify them and give
            them access to dedicated services. Depending on what is described below, third parties
            may provide registration and authentication services. In this case, this Application
            will be able to access some Data, stored by these third-party services, for registration
            or identification purposes. Some of the services listed below may also collect Personal
            Data for targeting and profiling purposes; to find out more, please refer to the
            description of each service.
          </p>
          <Heading level="4">Google OAuth</Heading>
          <p>
            Google OAuth is a registration and authentication service provided by Google LLC or by
            Google Ireland Limited, depending on the location this Application is accessed from, and
            is connected to the Google network.
          </p>
          <Heading level="4">Provider</Heading>
          <p>
            Google LLC <br />
            Google Ireland Limited
          </p>
          <Heading level="4">Personal Data collected</Heading>
          <p>
            No personal data is collected. The application uses the unique google user id to
            identify users.
          </p>
          <Heading level="3">User database management</Heading>
          <p>
            This type of service allows the Owner to build user profiles by starting from an email
            address, a personal name, or other information that the User provides to this
            Application, as well as to track User activities through analytics features. This
            Personal Data may also be matched with publicly available information about the User
            (such as social networks' profiles) and used to build private profiles that the Owner
            can display and use for improving this Application. Some of these services may also
            enable the sending of timed messages to the User, such as emails based on specific
            actions performed on this Application.
          </p>
          <Heading level="4">Back4App</Heading>
          <p>
            Back4App is a backend as a service solution based on the open source Parse Platform.
          </p>
          <Heading level="4">Provider</Heading>
          <p>Back4App, Inc, 440 N Wolfe Road, Sunnyvale, CA</p>
          <Heading level="4">Personal Data collected</Heading>
          <p>
            Non-Personal Information: <br />
            Information that alone cannot identify you. This includes the type of your device,
            settings and language.
          </p>
          <p>
            Personal Information: <br />
            NO personal information is collected. When using the online game mode, you need to set a
            player name but NO personal data like your email or real name is collected.
          </p>
          <Heading level="3">Advertising</Heading>
          <p>
            This type of service allows User Data to be utilized for advertising communication
            purposes displayed in the form of banners and other advertisements on this Application,
            possibly based on User interests. This does not mean that all Personal Data are used for
            this purpose. Information and conditions of use are shown below. Some of the services
            listed below may use Cookies to identify Users or they may use the behavioral
            retargeting technique, i.e. displaying ads tailored to the User’s interests and
            behavior, including those detected outside this Application. For more information,
            please check the privacy policies of the relevant services. <br />
            In addition to any opt-out offered by any of the services below, the User may opt out of
            a third-party service's use of cookies for certain advertising features by visiting the
            Network Advertising Initiative opt-out page. <br />
            Users may also opt-out of certain advertising features through applicable device
            settings, such as the device advertising settings for mobile phones or ads settings in
            general.
          </p>
          <Heading level="4">AdMob (Google Ireland Limited)</Heading>
          <p>
            AdMob is an advertising service provided by Google Ireland Limited. In order to
            understand Google's use of Data, consult{' '}
            <a href="https://www.google.com/policies/privacy/partners/" target="_blank">
              Google's partner policy
            </a>
            .
          </p>
          <Heading level="4">Personal Data processed:</Heading>
          <p>
            Cookies; unique device identifiers for advertising (Google Advertiser ID or IDFA, for
            example); Usage Data.
          </p>
          <p>Place of processing: Ireland. Privacy Shield participant.</p>
          <Heading level="3">Infrastructure monitoring</Heading>
          <p>
            This type of service allows this Application to monitor the use and behavior of its
            components so its performance, operation, maintenance and troubleshooting can be
            improved. Which Personal Data are processed depends on the characteristics and mode of
            implementation of these services, whose function is to filter the activities of this
            Application.
          </p>
          <Heading level="4">Sentry (Functional Software, Inc. )</Heading>
          <p>Sentry is a monitoring service provided by Functional Software, Inc.</p>
          <Heading level="4">Personal Data processed:</Heading>
          <p>various types of Data as specified in the privacy policy of the service.</p>
          <p>Place of processing: United States</p>

          <Heading level="2">The rights of Users</Heading>
          <p>
            Users may exercise certain rights regarding their Data processed by the Owner. In
            particular, Users have the right to do the following:
          </p>
          <ul>
            <li>
              Withdraw their consent at any time. Users have the right to withdraw consent where
              they have previously given their consent to the processing of their Personal Data.
            </li>
            <li>
              Object to processing of their Data. Users have the right to object to the processing
              of their Data if the processing is carried out on a legal basis other than consent.
              Further details are provided in the dedicated section below.
            </li>
            <li>
              Access their Data. Users have the right to learn if Data is being processed by the
              Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy
              of the Data undergoing processing.
            </li>
            <li>
              Verify and seek rectification. Users have the right to verify the accuracy of their
              Data and ask for it to be updated or corrected.
            </li>
            <li>
              Restrict the processing of their Data. Users have the right, under certain
              circumstances, to restrict the processing of their Data. In this case, the Owner will
              not process their Data for any purpose other than storing it.
            </li>
            <li>
              Have their Personal Data deleted or otherwise removed. Users have the right, under
              certain circumstances, to obtain the erasure of their Data from the Owner.
            </li>
            <li>
              Receive their Data and have it transferred to another controller. Users have the right
              to receive their Data in a structured, commonly used and machine readable format and,
              if technically feasible, to have it transmitted to another controller without any
              hindrance. This provision is applicable provided that the Data is processed by
              automated means and that the processing is based on the User's consent, on a contract
              which the User is part of or on pre-contractual obligations thereof.
            </li>
            <li>
              Lodge a complaint. Users have the right to bring a claim before their competent data
              protection authority.
            </li>
          </ul>
          <Heading level="3">Details about the right to object to processing</Heading>
          <p>
            Where Personal Data is processed for a public interest, in the exercise of an official
            authority vested in the Owner or for the purposes of the legitimate interests pursued by
            the Owner, Users may object to such processing by providing a ground related to their
            particular situation to justify the objection. Users must know that, however, should
            their Personal Data be processed for direct marketing purposes, they can object to that
            processing at any time without providing any justification. To learn, whether the Owner
            is processing Personal Data for direct marketing purposes, Users may refer to the
            relevant sections of this document.
          </p>
          <Heading level="3">How to exercise these rights</Heading>
          <p>
            Any requests to exercise User rights can be directed to the Owner through the contact
            details provided in this document. These requests can be exercised free of charge and
            will be addressed by the Owner as early as possible and always within one month.
          </p>
        </Content>
      </Container>

      <Footer />
    </Layout>
  )
}

// ================================================================================================

const BackHomeLink = styled(Link)`
  display: inline-block;
  font-size: 1rem;
  color: var(--primary-color);
  text-decoration: none;
`

const Heading = styled.h1`
  font-size: ${({ level }) => (level === '1' ? '2rem' : level === '2' ? '1.5rem' : '1rem')};
  color: var(--text-color);
  margin: ${({ level }) =>
    level === '1'
      ? '0 0 3rem'
      : level === '2'
      ? '3rem 0 1.5rem 0'
      : level === '3'
      ? '2rem 0 0.75rem'
      : '1rem 0 0.5rem'};

  @media screen and (min-width: 600px) {
    font-size: ${({ level }) =>
      level === '1' ? '2.5rem' : level === '2' ? '1.75rem' : level === '3' ? '1.375rem' : '1rem'};
  }
`

const Content = styled.div`
  padding: 4rem 0;

  p {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  a {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1rem;
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
    }
  }

  ul {
    margin: 0 0 1rem 1.375rem;
  }
`

// ================================================================================================

export default injectIntl(PrivacyApp)
